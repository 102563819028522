import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Title from "../components/title/subtitle"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Paginations from "../components/pagination"
import { Select } from "antd"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { createHistory } from "@reach/router"
import { navigate } from "@reach/router"
import classes from "../components/single.module.css"

gsap.registerPlugin(ScrollTrigger)

const Blog = ({ data, location }) => {
  //Data
  const { allContentfulBlogPost, allContentfulBlogCategory } = data
  //History
  const history = typeof window !== "undefined" ? createHistory(window) : null

  //Ant Blog
  const { Option } = Select

  ///////////////////////////////PAGINATION START//////////////////////////////////////
  //Pagenation Use State
  const [posts, setPosts] = useState(allContentfulBlogPost.edges)
  const [postsPerPage] = useState(12)

  let page
  let category
  //ビルドエラーのため
  if (location.state !== undefined) {
    //ビルドエラーのため
    if (
      location.state.page !== undefined ||
      location.state.category !== undefined
    ) {
      //他のページからデザインページに移動した時
      if (
        location.state.page === undefined &&
        location.state.category === undefined
      ) {
        page = 1
        category = "all"
      } else {
        page = location.state.page
        category = location.state.category
      }
    } else {
      page = 1
      category = "all"
    }
  } else {
    page = 1
    category = "all"
  }

  const [currentPage, setCurrentPage] = useState(page)
  const [selectCategory, setSelectCategory] = useState(category)

  //Pagenation
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

  /*  console.log("location.state.page", location.state.page)
  console.log("location.state.category", location.state.category)
  console.log("currentPage", currentPage)
  console.log("selectCategory", selectCategory) */

  ///////////////////////////////PAGINATION END//////////////////////////////////////
  ///////////////////////////////SERECT START////////////////////////////////////////
  const paginate = index => {
    setCurrentPage(index)
    navigate(`#page=${index}&category=${selectCategory}`, {
      state: { page: index, category: selectCategory },
    })
  }
  const updateCategorySelection = categoryValue => {
    filterPosts(categoryValue)
    setCurrentPage(1)
    setSelectCategory(categoryValue)
    navigate(`#page=1&category=${categoryValue}`, {
      state: { page: 1, category: categoryValue },
    })
  }

  const filterPosts = () => {
    if (selectCategory === "all") {
      setPosts(allContentfulBlogPost.edges)
    } else {
      let posts = allContentfulBlogPost.edges.filter(post =>
        post.node.category ? post.node.category.slug === selectCategory : null
      )
      setPosts(posts)
    }
    window.scroll(0, 0)
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".scroll",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.3 }
    )
  }
  useEffect(filterPosts, [selectCategory, currentPage])

  ///////////////////////////////HISTORY START//////////////////////////////////////
  useEffect(() => {
    const unlisten = history.listen(history => {
      setCurrentPage(history.location.state.page)
      setSelectCategory(history.location.state.category)
      if (
        history.location.state.page === undefined &&
        history.location.state.category === undefined
      ) {
        setCurrentPage(1)
        setSelectCategory("all")
      }
    })
    return unlisten
  }, [history])
  ///////////////////////////////HISTORY END//////////////////////////////////////

  return (
    <Layout>
      <SEO
        title="ブログ"
        description={`しょうのまきのブログはこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。`}
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="page-head">
        <Title title="ブログ" />
      </header>

      <article className="post-content page-template no-image">
        <div className="select-wrap">
          <Select
            style={{ width: 300 }}
            placeholder="カテゴリーで絞り込みする"
            defaultValue={"all"}
            value={selectCategory}
            onChange={value => updateCategorySelection(value)}
          >
            <Option value={"all"}>全て</Option>

            {allContentfulBlogCategory.edges.map(category => (
              <Option value={category.node.slug} key={category.node.slug}>
                {category.node.name}
              </Option>
            ))}
          </Select>
        </div>

        {currentPosts.length > 0 ? (
          <div className="scroll">
            <div className="row">
              {currentPosts.map(b => (
                <div className={`col-6 ${classes.blogArchive} ${b.node.id}`}>
                  <Link
                    className="post-archive-link"
                    to={`/blog/${b.node.slug}`}
                  >
                    {b.node.heroImage ? (
                      <Img
                        className="kg-image"
                        fluid={b.node.heroImage ? b.node.heroImage.fluid : null}
                        alt={b.node.title}
                      />
                    ) : (
                      <Img
                        className="kg-image"
                        fluid={data.file.childImageSharp.fluid}
                        alt="ブログの画像"
                      />
                    )}
                  </Link>

                  {b.node.category ? (
                    <p className={` ${classes.category}`}>
                      <Link to={`/blog/category/${b.node.category.slug}`}>
                        {b.node.category.name}
                      </Link>
                    </p>
                  ) : null}

                  <h2>
                    <Link to={`/blog/${b.node.slug}`}>{b.node.title}</Link>
                  </h2>
                  <div
                    className={classes.descArchive}
                    dangerouslySetInnerHTML={{
                      __html: b.node.description.childMarkdownRemark.html,
                    }}
                  />
                  <div className={`post-content-date ${classes.date}`}>
                    {b.node.publishDate}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="post-content center">
            <div className="post-content-body opacity">
              <p>まだ事例がありません</p>
            </div>
          </div>
        )}

        {posts.length > postsPerPage ? (
          <Paginations
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
          />
        ) : null}
      </article>
    </Layout>
  )
}
export const query = graphql`
  query blogListQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 600
          maxHeight: 600
          cropFocus: CENTER
          quality: 85
          traceSVG: { turnPolicy: TURNPOLICY_MINORITY, blackOnWhite: false }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    allContentfulBlogCategory(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          slug
          name
        }
      }
    }

    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          id
          publishDate(formatString: "YYYY年MM月DD日")
          category {
            name
            slug
          }
          heroImage {
            fluid(
              resizingBehavior: PAD
              maxWidth: 768
              maxHeight: 500
              quality: 85
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
export default Blog

/*
fluid(maxWidth: 600, maxHeight: 600, resizingBehavior: PAD
  ) {
          ...GatsbyContentfulFluid_tracedSVG
        }

        <select onChange={e => filterPosts(e.target.value)} value={selected}>
          <option value="all" selected>--カテゴリーで絞り込みする</option>
          {allContentfulBlogCategory.edges.map(category => (
            <option value={category.node.slug}>{category.node.name}</option>
          ))}
        </select>

      */

/*   const [apiUrl, setApiUrl] = useState('http://localhost:8000/');
const onChange = (e, pageInfo) => {
setActivePage(pageInfo.activePage);
setApiUrl('http://localhost:8000/?page=' + page.activePage.toString());
}; */
/*   ページネーション追加テスト　ここまで */

//history.navigate(history.location.hash , { state: { page: parseInt(pageNumber) } })

/*   useEffect(() => {
      console.log("currentPage", currentPage)
      console.log("paginate happened!")
          
          history.navigate("#page=" + currentPage, {
            state: { page: parseInt(currentPage) },
          })
    }, [currentPage]) */
